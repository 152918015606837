<div class="dashboard-panel h-100">
  <h1 class="subtitle">{{ 'NOTIFICATIONS_CREATE.TITLE' | translate }}</h1>
  <div class="ui-g h-100">
    <div class="ui-g-12">
      <div class="card h-100">
        <div class="card-body">
          <ng-container *ngIf="notificationForm$ | async as notificationForm">
            <form [formGroup]="notificationForm">
              <div class="form-group">
                <label for="title" class="d-block">{{ 'NOTIFICATIONS_CREATE.TITLE_LABEL' | translate }}</label>
                <input id="title"
                       pInputText
                       class="w-100"
                       formControlName="title" />
                <div *ngIf="notificationForm.get('title').invalid && notificationForm.get('title').touched"
                     class="alert alert-danger">
                  <span *ngIf="notificationForm.get('title').errors.required">
                    {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="notification" class="d-block">{{ 'NOTIFICATIONS_CREATE.MESSAGE' | translate }}</label>
                <textarea id="notification"
                          pInputTextarea
                          rows="5"
                          class="w-100"
                          formControlName="message"
                          style="margin-bottom: -5px">
              </textarea>
                <div *ngIf="notificationForm.get('message').invalid && notificationForm.get('message').touched"
                     class="alert alert-danger">
                  <span *ngIf="notificationForm.get('message').errors.required">
                    {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <label for="user-roles" class="d-block mt-3">{{ 'NOTIFICATIONS_CREATE.GROUPS' | translate }}</label>
                <p-multiSelect id="user-roles"
                               [options]="userRoles$ | async"
                               formControlName="roles"
                               optionLabel="userRole">
                </p-multiSelect>
                <div *ngIf="notificationForm.get('roles').invalid && notificationForm.get('roles').touched" class="alert alert-danger">
                  <span *ngIf="notificationForm.get('roles').errors.required">
                    {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
                  </span>
                </div>
              </div>
            </form>
          </ng-container>
        </div>
        <div class="card-footer">
          <p-button
                  type="submit"
                  [label]="'COMMON.SUBMIT' | translate"
                  (click)="createNotification()">
          </p-button>
        </div>
      </div>
    </div>
  </div>
</div>


