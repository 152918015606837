import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UserPoints } from '../interfaces/user-points';
import { LazyLoadEvent } from 'primeng/api';
import { UsersResponse } from '../../merit/shared/models/users-response';
import { tap } from 'rxjs/operators';
import { RateData } from '../interfaces/rate-data';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private personalReportSub: BehaviorSubject<any> = new BehaviorSubject(null);
  private memberAddedSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  pageSize = 10;
  order = "DESC";
  orderBy = "last_name";

  private userPoints: BehaviorSubject<UserPoints> = new BehaviorSubject<UserPoints>(null);
  private userList: BehaviorSubject<UsersResponse> = new BehaviorSubject(null);

  constructor(private apiSvc: ApiService) { }

  personalReportResponse$(): Observable<any> {
    return this.personalReportSub.asObservable();
  }

  getPersonalReport(reqObj) {
    this.apiSvc.post('/user/personal-report', reqObj)
      .subscribe((res: any) => {
        this.personalReportSub.next(res);
      });
  }

  memberAdded$(): Observable<boolean> {
    return this.memberAddedSub.asObservable();
  }

  editPoints(editObj) {
    return this.apiSvc.post('/user/edit-point', editObj);
  }

  userListByPeriodId$(): Observable<UsersResponse> {
    return this.userList.asObservable();
  }

  getUserListByPeriodId(periodId, query, event?: LazyLoadEvent) {
    this.apiSvc.post('/user/point-distribution', {
      periodId,
      page: event ? event.first / event.rows + 1 : 1,
      resultsPerPage: this.pageSize,
      order: this.order,
      orderBy: this.orderBy,
      query
    }).subscribe((usersResponse: UsersResponse) => {
      this.userList.next(usersResponse);
    });
  }

  getUserDetailsByPeriodId(periodId, targetUserId, event?: LazyLoadEvent) {
    return this.apiSvc.post('/user/point-distribution-details', {
      periodId,
      page: event ? event.first / event.rows + 1 : 1,
      resultsPerPage: this.pageSize,
      order: this.order,
      orderBy: 'date_created',
      targetUserId
    }).pipe(
        tap((data: any) => {
        let sum = 0;

        data.content.forEach((transaction) => {
          sum = sum + transaction.points;
        })

        this.userList.getValue().content.find((user => user.userId === targetUserId)).points = sum;
        this.userList.next(this.userList.getValue());
    }));
  }

  rateUser(rateData) {
    return this.apiSvc.post('/user/rate', rateData);
  }

  userPoints$(): Observable<UserPoints> {
    return this.userPoints.asObservable();
  }

  getPoints(periodId): void {
    this.apiSvc.get('/user/points/' + periodId)
      .subscribe((userPoints: UserPoints) => {
        this.userPoints.next(userPoints);
      });
  }

  getUserData() {
    return this.apiSvc.get('/user/my');
  }

  getUserSettings() {
    return this.apiSvc.get('/user/settings');
  }

  updateUserList(rateData: RateData): void {
    const index = this.userList.getValue().content.findIndex(user => user.userId === rateData.toUserId);

    this.userList.getValue().content[index].points += rateData.points;
    this.userList.getValue().content[index].reason = rateData.reason;

    this.userList.next(this.userList.getValue());
  }

  requestPasswordResetLink(email: string) {
    const body = {
      email
    };
    return this.apiSvc.post('/user/forgotten-password', body);
  }

  verifyResetPasswordToken(token: string) {
    const body = {
      token
    };
    return this.apiSvc.post('/user/reset-password/verify-token', body);
  }

  resetPassword(token: string, newPassword: string) {
    const body = {
      token,
      newPassword
    };
    return this.apiSvc.post('/user/reset-password', body);
  }
}
