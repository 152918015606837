import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { InviteListComponent } from './invite-list/invite-list.component';
import { NotificationsCreateComponent } from '../shared/components/notifications-create/notifications-create.component';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'settings',
    component: AppSettingsComponent
  },
  {
    path: 'invite-list',
    component: InviteListComponent
  },
  {
    path: 'create-notifications',
    component: NotificationsCreateComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
