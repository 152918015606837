import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateForm } from '../utils/validation';
import { ApiService } from './api.service';
import { select, Store } from '@ngrx/store';
import * as AuthSelectors from '../store/auth/auth.selectors';
import { map } from 'rxjs/operators';
import { UserRoles } from '../constants/user-roles';
import * as fromApp from '../store';
import { MerriteMessageService } from '../../core/shared/services/merrite-message.service';

@Injectable({
  providedIn: 'root'
})
export class CreateNotificationService {
  private notificationFormSub: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(this.formBuilder.group({
    message: [null, [Validators.required]],
    title: [null, [Validators.required]],
    roles: [null, [Validators.required]]
  }));

  constructor(private formBuilder: FormBuilder, private apiSvc: ApiService,
              private messageSvc: MerriteMessageService, private store: Store<fromApp.AppState>) {
  }

  roles$(): Observable<any> {
    return this.store
      .pipe(
        select(AuthSelectors.selectUserRoles),
        map((roles: UserRoles[]) => {
          const userRoles = [];

          for (const userRole in UserRoles) {
            if (roles.indexOf(UserRoles.ADMIN) > -1) {
              userRoles.push({ userRole });
            } else if (roles.indexOf(UserRoles.OWNER) > -1 && userRole !== UserRoles.ADMIN && userRole !== UserRoles.OWNER) {
              userRoles.push({ userRole });
            } else if (roles.indexOf(UserRoles.MANAGER) > -1 && userRole !== UserRoles.ADMIN) {
              userRoles.push({ userRole });
            }
          }

          return userRoles;
        })
      );
  }

  notificationForm$(): Observable<FormGroup> {
    return this.notificationFormSub.asObservable();
  }

  createNotification() {
    validateForm(this.notificationFormSub.getValue());
    if (!this.notificationFormSub.getValue().valid) {
      return;
    }

    const reqObject = {
      roleNames: this.notificationFormSub.getValue().get('roles').value.map(item => item.userRole),
      message: this.notificationFormSub.getValue().get('message').value,
      title: this.notificationFormSub.getValue().get('title').value
    };

    return this.apiSvc.post('/owner/notifications/create', reqObject)
      .subscribe(() => {
        this.messageSvc.publish('NOTIFICATION_CREATED');
        this.resetForm();
      });
  }

  resetForm(): void {
    this.notificationFormSub.getValue().reset();
    this.notificationFormSub.next(this.notificationFormSub.getValue());
  }
}
