import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import * as fromApp from '../../shared/store';
import * as AuthActions from '../../shared/store/auth/auth.actions';
import * as LanguageSelectors from '../../shared/store/language/language.selectors';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderAdminComponent implements OnInit, OnDestroy {

  items: MenuItem[];

  destroyed$: Subject<any> = new Subject();

  constructor(private translateSvc: TranslateService, private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.store
      .pipe(
        takeUntil(this.destroyed$),
        select(LanguageSelectors.selectSelectedLanguageLocale)
      )
      .subscribe((locale: string) => {
         // assure translations file is loaded before using instant
         this.translateSvc.getTranslation(locale)
           .pipe(
             takeUntil(this.destroyed$)
           )
           .subscribe(() => {
             this.initializeMenuBar();
           });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  initializeMenuBar() {
    this.items = [
      {
        label: this.translateSvc.instant('ADMIN.MENUBAR.DASHBOARD'),
        routerLink: ['/admin/dashboard']
      },
      {
        label: this.translateSvc.instant('ADMIN.MENUBAR.APPLICATION'),
        items: [
          {
            label: this.translateSvc.instant('ADMIN.MENUBAR.SETTINGS'),
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/admin/settings']
          },
        ]
      },
      {
        label: this.translateSvc.instant('ADMIN.MENUBAR.NOTIFICATIONS_CREATE'),
        routerLink: ['/admin/create-notifications']
      },
      {
        label: this.translateSvc.instant('ADMIN.MENUBAR.INVITE_LIST'),
        routerLink: ['/admin/invite-list']
      }
    ];
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

}
