import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UserRoles } from '../../constants/user-roles';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CreateNotificationService } from '../../services/create-notification.service';

@Component({
  selector: 'app-notifications-create',
  templateUrl: './notifications-create.component.html',
  styleUrls: ['./notifications-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsCreateComponent implements OnInit {
  userRoles$: Observable<UserRoles[]> = this.notificationSvc.roles$();
  notificationForm$: Observable<FormGroup> = this.notificationSvc.notificationForm$();

  constructor(private notificationSvc: CreateNotificationService) { }

  ngOnInit() {
    this.notificationSvc.resetForm();
  }

  createNotification(): void {
    this.notificationSvc.createNotification();
  }
}
