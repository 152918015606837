<div class="front-bg">
  <div class="front">
    <div class="front-content">
      <div class="brand"><img src="assets/img/meerite-logo.svg" height="40" alt="meerite"></div>
      <h1 class="text-center mb-5">Best evaluation tool for your team!
        <span>Team evaluation has never been so easy.</span></h1>
      <p-button [routerLink]="!(isLoggedIn$ | async) ? '/auth/login' : '/merit'"
                [label]="'LANDING.GET_STARTED' | translate"></p-button>
    </div>
  </div>
</div>
<app-home-background></app-home-background>
<app-footer></app-footer>



